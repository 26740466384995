<template>
  <div>
    <v-form
      class="px-3"
      ref="form"
      @submit="submitForm()"
      @submit.prevent
      :value="valid"
    >
      <v-container fluid>
        <v-row style="mx-4">
          <v-col cols="12">
            <v-text-field
              label="First Name"
              v-if="
                type === 'Register' ||
                  type === 'Create' ||
                  type === 'Edit'
              "
              v-model="user.firstName"
              :rules="[requiredRule]"
            />
            <v-text-field
              label="Last Name"
              v-if="
                type === 'Register' ||
                  type === 'Create' ||
                  type === 'Edit'
              "
              v-model="user.lastName"
              :rules="[requiredRule]"
            />
            <v-text-field
              label="E-Mail"
              v-if="
                type === 'Register' ||
                  type === 'Create' ||
                  type === 'Edit' ||
                  type === 'Login' ||
                  type === 'ForgotPassword' ||
                  type === 'Delete'
              "
              v-model="user.email"
              :rules="[requiredRule, emailRule]"
              :disabled="type === 'Delete'"
            />
            <v-text-field
              label="Retype E-Mail"
              v-if="type === 'Delete'"
              v-model="emailRepeat"
              :rules="[requiredRule, matchingEmail]"
            />
            <v-text-field
              label="Password"
              v-if="
                type === 'Register' ||
                  type === 'Login' ||
                  type === 'Create' ||
                  type == 'ResetPassword'
              "
              v-model="user.password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              :rules="[requiredRule]"
              @click:append="showPassword = !showPassword"
            />
            <v-text-field
              label="Repeat Password"
              v-if="
                type === 'Register' ||
                  type === 'Create' ||
                  type == 'ResetPassword'
              "
              v-model="user.passwordRepeat"
              :append-icon="
                showPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off'
              "
              :type="showPasswordRepeat ? 'text' : 'password'"
              :rules="[requiredRule, matchingPassword]"
              @click:append="showPasswordRepeat = !showPasswordRepeat"
            />
            <v-row>
              <v-col cols="12" sm="6">
                <v-switch
                  v-if="type === 'Create' || type === 'Edit'"
                  v-model="user.emailConfirmed"
                  label="E-mail confirmed"
                ></v-switch>
                <v-switch
                  v-if="type === 'Create' || type === 'Edit'"
                  v-model="user.activated"
                  @change="
                    user.admin = !user.activated ? false : user.admin
                  "
                  label="Activated"
                ></v-switch>
                <v-switch
                  v-if="type === 'Create' || type === 'Edit'"
                  :disabled="!user.activated"
                  v-model="user.admin"
                  label="Admin"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="6"> </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-btn
          v-if="
            type === 'Register' ||
              type === 'Login' ||
              type === 'ForgotPassword' ||
              type === 'ResetPassword'
          "
          type="submit"
        >
          {{ buttonText }}
        </v-btn>
      </v-container>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'UserAuthForm',
  props: {
    type: { type: String, required: true },
    value: { type: Object, required: true },
  },
  data() {
    return {
      valid: false,
      dialog: false,
      showPassword: false,
      showPasswordRepeat: false,
      emailRepeat: '',
      requiredRule: value => !!value || 'Field is required',
      emailRule: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      matchingPassword: v =>
        v === this.user.password || 'Passwords do not match',
      matchingEmail: v =>
        v === this.user.email || 'E-mail does not match',
    };
  },
  methods: {
    submitForm() {
      if (this.$refs.form.validate()) {
        this.$emit('submitForm', this.user);
      } else {
        var snack = {
          text:
            'Some inputs are missing or not correct, please check!',
          color: 'error',
        };
        this.$store.dispatch('misc/setSnack', snack);
      }
    },

    reset() {
      this.$refs.form.reset();
    },
  },
  computed: {
    buttonText() {
      switch (this.type) {
        case 'Login':
          return 'Login';
        case 'Register':
          return 'Register';
        case 'Create':
          return 'Create';
        case 'Edit':
          return 'Edit';
        case 'ResetPassword':
          return 'Reset Password';
        default:
          return 'Submit';
      }
    },
    user: {
      get: function() {
        return this.value;
      },
      set: function(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
</script>

<style></style>
