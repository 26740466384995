<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn small v-on="on" class="error" @click="openDialog">
          <v-icon>
            mdi-nuke
          </v-icon>
        </v-btn>
      </template>
      <span> Delete Account </span>
    </v-tooltip>

    <v-dialog
      v-model="dialog"
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar flat class="titlebar" max-height="64">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon @click="close" v-on="on">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </template>
            <span> Cancel </span>
          </v-tooltip>
          <v-toolbar-title> Delete Account </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                icon
                class="success"
                @click="deleteUser"
                v-on="on"
              >
                <v-icon> mdi-send </v-icon>
              </v-btn>
            </template>
            <span> Submit </span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <p>
            To confirm the account deletion, please re-type your
            E-Mail. Keep in mind that the deletion is permanent.
          </p>
          <UserAuthForm
            v-if="user"
            ref="userAuthForm"
            type="Delete"
            v-model="user"
            @submitForm="deleteUser"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserAuthForm from '@/components/auth/UserAuthForm';

export default {
  name: 'UserDeletePopup',
  props: {
    inputUser: { type: Object, required: true },
  },
  components: { UserAuthForm },
  data() {
    return {
      dialog: false,
      loading: false,
      panels: [0, 1],
      user: JSON.parse(JSON.stringify(this.inputUser)),
    };
  },
  methods: {
    deleteUser() {
      this.$emit('deleteUser', this.user);
      this.close();
    },
    close() {
      this.reset();
      this.dialog = false;
    },
    reset() {
      this.user = JSON.parse(JSON.stringify(this.inputUser));
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
    openDialog(e) {
      e.cancelBubble = true;
      this.reset();
      this.dialog = true;
    },
  },
  computed: {},
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
