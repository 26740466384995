<template>
  <v-container fluid>
    <v-expansion-panels v-model="panels" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Color Theme</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-select
            label="Theme"
            v-model="currentUser.settings.theme"
            :items="themeNames"
            @change="updateUserSettings"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h2>Delete Account</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <UserDeletePopup
            :inputUser="currentUser"
            @deleteUser="deleteUser"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import UserDeletePopup from '@/components/auth/UserDeletePopup';

export default {
  components: { UserDeletePopup },
  data() {
    return {
      panels: [0, 1],
    };
  },
  methods: {
    updateUserSettings() {
      this.$store.dispatch('misc/setLoading', true);
      this.$store
        .dispatch('updateUserSettings', this.currentUser.settings)
        .then(response => {
          this.$store.dispatch('misc/setLoading', false);
          var snack = {
            text: response.data.msg,
            color: 'success',
          };
          this.$store.dispatch(
            'misc/changeTheme',
            this.currentUser.settings.theme,
          );
          this.$store.dispatch('misc/setSnack', snack);
        })
        .catch(error => {
          this.$store.dispatch('misc/setLoading', false);
          console.log(error);
          console.log(error.response.data.msg);
          var snack = {
            text: error.response.data.msg,
            color: 'error',
          };
          this.$store.dispatch('misc/setSnack', snack);
        });
    },
    deleteUser() {
      this.$store.dispatch('misc/setLoading', {
        status: true,
        text: 'Deleting Account. Please wait...',
      });
      this.$store
        .dispatch('deleteUser', this.currentUser.publicId)
        .then(response => {
          this.$store.dispatch('misc/setSnack', {
            text: response.data.msg,
            color: 'success',
          });
          this.$store.dispatch('logoutUser', { force: true });
        })
        .catch(error => {
          console.log(error);
          this.$store.dispatch('misc/setSnack', {
            text: error.response.data.msg,
            color: 'error',
          });
        })
        .finally(() => {
          this.$store.dispatch('misc/setLoading', false);
          this.$router.push({ name: 'Login' });
        });
    },
  },
  computed: {
    ...mapGetters(['currentUser']),
    ...mapGetters('misc', ['themes']),
    themeNames() {
      return Object.keys(this.themes);
    },
  },
};
</script>

<style></style>
