var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"form",staticClass:"px-3",attrs:{"value":_vm.valid},on:{"submit":[function($event){return _vm.submitForm()},function($event){$event.preventDefault();}]}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticStyle:{}},[_c('v-col',{attrs:{"cols":"12"}},[(
              _vm.type === 'Register' ||
                _vm.type === 'Create' ||
                _vm.type === 'Edit'
            )?_c('v-text-field',{attrs:{"label":"First Name","rules":[_vm.requiredRule]},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}}):_vm._e(),(
              _vm.type === 'Register' ||
                _vm.type === 'Create' ||
                _vm.type === 'Edit'
            )?_c('v-text-field',{attrs:{"label":"Last Name","rules":[_vm.requiredRule]},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}}):_vm._e(),(
              _vm.type === 'Register' ||
                _vm.type === 'Create' ||
                _vm.type === 'Edit' ||
                _vm.type === 'Login' ||
                _vm.type === 'ForgotPassword' ||
                _vm.type === 'Delete'
            )?_c('v-text-field',{attrs:{"label":"E-Mail","rules":[_vm.requiredRule, _vm.emailRule],"disabled":_vm.type === 'Delete'},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}):_vm._e(),(_vm.type === 'Delete')?_c('v-text-field',{attrs:{"label":"Retype E-Mail","rules":[_vm.requiredRule, _vm.matchingEmail]},model:{value:(_vm.emailRepeat),callback:function ($$v) {_vm.emailRepeat=$$v},expression:"emailRepeat"}}):_vm._e(),(
              _vm.type === 'Register' ||
                _vm.type === 'Login' ||
                _vm.type === 'Create' ||
                _vm.type == 'ResetPassword'
            )?_c('v-text-field',{attrs:{"label":"Password","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"rules":[_vm.requiredRule]},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}):_vm._e(),(
              _vm.type === 'Register' ||
                _vm.type === 'Create' ||
                _vm.type == 'ResetPassword'
            )?_c('v-text-field',{attrs:{"label":"Repeat Password","append-icon":_vm.showPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPasswordRepeat ? 'text' : 'password',"rules":[_vm.requiredRule, _vm.matchingPassword]},on:{"click:append":function($event){_vm.showPasswordRepeat = !_vm.showPasswordRepeat}},model:{value:(_vm.user.passwordRepeat),callback:function ($$v) {_vm.$set(_vm.user, "passwordRepeat", $$v)},expression:"user.passwordRepeat"}}):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.type === 'Create' || _vm.type === 'Edit')?_c('v-switch',{attrs:{"label":"E-mail confirmed"},model:{value:(_vm.user.emailConfirmed),callback:function ($$v) {_vm.$set(_vm.user, "emailConfirmed", $$v)},expression:"user.emailConfirmed"}}):_vm._e(),(_vm.type === 'Create' || _vm.type === 'Edit')?_c('v-switch',{attrs:{"label":"Activated"},on:{"change":function($event){_vm.user.admin = !_vm.user.activated ? false : _vm.user.admin}},model:{value:(_vm.user.activated),callback:function ($$v) {_vm.$set(_vm.user, "activated", $$v)},expression:"user.activated"}}):_vm._e(),(_vm.type === 'Create' || _vm.type === 'Edit')?_c('v-switch',{attrs:{"disabled":!_vm.user.activated,"label":"Admin"},model:{value:(_vm.user.admin),callback:function ($$v) {_vm.$set(_vm.user, "admin", $$v)},expression:"user.admin"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}})],1)],1)],1),(
          _vm.type === 'Register' ||
            _vm.type === 'Login' ||
            _vm.type === 'ForgotPassword' ||
            _vm.type === 'ResetPassword'
        )?_c('v-btn',{attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }